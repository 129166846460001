<template>
    <UsersListing />
</template>

<script>
import UsersListing from '@/views/apps/customers/UsersListing.vue'
import {Actions} from "../../store/enums/StoreEnums";
import store from "@/store";

export default {
  name: "Subscribers",
  components: {
    UsersListing
  },
  created() {
    // fetch all users from api
    store.dispatch(Actions.FETCH_USERS)
  },
  beforeRouteUpdate: (to, from, next) => {
    store.dispatch(Actions.FETCH_USERS)
        .then(() => next())

  },
}
</script>

<style scoped>

</style>