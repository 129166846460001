
import { computed, defineComponent, ref, onMounted, watch } from "vue";
import arraySort from "array-sort";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "datatable",
  props: {
    tableHeader: { type: Array, required: true },
    tableData: { type: Array, required: true },
    enableItemsPerPageDropdown: Boolean,
    rowsPerPage: Number,
  },
  components: {},
  setup(props) {
    const currentSort = ref<string>("");
    const click = ref<number>(1);
    const pagination = ref<IPagination>({
      page: 1,
      total: 0,
      rowsPerPage: 10,
    });

    const calcPug = (val) => {
      pagination.value.total = val;
    };

    const getIT = computed(() => {
      const clone = JSON.parse(JSON.stringify(props.tableData));
      calcPug(clone.length);
      const startFrom =
        pagination.value.page * pagination.value.rowsPerPage -
        pagination.value.rowsPerPage;
      const arr = clone.splice(startFrom, pagination.value.rowsPerPage);
      return arr ? arr : [];
    });

    onMounted(() => {
      pagination.value.rowsPerPage = props.rowsPerPage ? props.rowsPerPage : 10;
      pagination.value.total = props.tableData.length;
    });

    const pages = computed(() => {
      return Math.ceil(pagination.value.total / pagination.value.rowsPerPage);
    });
    const setCurrent = (val) => {
      pagination.value.page = val;
    };
    const sort = (columnName, sortable) => {
      if (!sortable) {
        return;
      }

      if (click.value === 2) {
        click.value = 1;
        arraySort(getIT.value, columnName, { reverse: false });
      } else {
        click.value++;
        arraySort(getIT.value, columnName, { reverse: true });
      }
      currentSort.value = columnName + click.value;
    };

    return {
      pagination,
      pages,
      setCurrent,
      getIT,
      sort,
      currentSort,
    };
  },
});
