
import { computed, defineComponent, onMounted, ref, onUpdated } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import { useI18n } from "vue-i18n";

interface ICustomer {
  id: number;
  name: string;
  email: string;
  phone?: string;
  role?: string;
  subscriptions?: string;
}

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    AddCustomerModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const search = ref<string>("");
    const { t } = useI18n();

    const searchItem = (item, searchValue) => {
      return item.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
    };

    const usersList = computed(() => {
      const data = store.getters.getUsersList

      let arr: Array<ICustomer> = []
      data.forEach((item) => {
        if (
          searchItem(item.email, search.value) ||
          searchItem(item.fname, search.value) ||
          searchItem(String(item.phone), search.value) ||
          searchItem(item.lname, search.value)
        ) {
          arr.push(item)
        }
      });
      return arr ? arr : []
    });

    const currentRouteName = computed(() => {
      return route.name;
    });

    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: t("firstName"),
        key: "name",
        sortable: true,
      },
      {
        name: t("email"),
        key: "email",
        sortable: true,
      },
      {
        name: t("phoneNumber"),
        key: "phone",
        sortable: true,
      },
      {
        name: t("role"),
        key: "role",
        sortable: true,
      },
      {
        name: t("subscriptions"),
        key: "subscriptions",
        sortable: true,
      },

      {
        name: t("actions"),
        key: "actions",
      },
    ]);

    const customers = ref<Array<ICustomer>>([
      {
        id: Math.floor(Math.random() * 99999) + 1,
        name: "Emma Smith",
        phone: "+7(123)-233-33-44",
        role: "Sub",
        email: "e.smith@kpmg.com.com",
        subscriptions: "4",
      },
    ]);
    const initCustomers = ref<Array<ICustomer>>([]);

    /*onBeforeRouteUpdate((to, from, next) => {
          console.log('UPDATED')
      // вызывается когда маршрут, что рендерит этот компонент изменился,
      // но этот компонент будет повторно использован в новом маршруте.
      // Например, для маршрута с динамическими параметрами `/foo/:id`, когда мы
      // перемещаемся между `/foo/1` и `/foo/2`, экземпляр того же компонента `Foo`
      // будет использован повторно, и этот хук будет вызван когда это случится.
      // Также имеется доступ в `this` к экземпляру компонента.
    });*/

    onUpdated(() => {
      console.log('Updated')
      // store.dispatch(Actions.FETCH_USERS)
    })

    onMounted(() => {
      MenuComponent.reinitialization();
      initCustomers.value.splice(0, customers.value.length, ...customers.value);

      setCurrentPageBreadcrumbs("Users", []);
    });

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const goDetails = (id) => {
      store.dispatch(Actions.FETCH_USER, { id: id }).then(() => {
        router.push({ name: "user", params: { id: id } });
      });
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < customers.value.length; i++) {
        if (customers.value[i].id === id) {
          customers.value.splice(i, 1);
        }
      }
    };

    return {
      usersList,
      currentRouteName,
      customers,
      tableHeader,
      deleteCustomer,
      search,
      checkedCustomers,
      deleteFewCustomers,
      goDetails,
      t,
    };
  },
});
